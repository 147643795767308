import React from "react"

const Saving = () => {
  return (
    <svg
      width="142"
      height="45"
      viewBox="0 0 142 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Saving...">
        <path
          id="S"
          d="M10.2398 29.36C7.93577 29.36 5.89577 28.868 4.11977 27.884C2.36777 26.9 1.11977 25.592 0.375768 23.96C0.183768 23.576 0.171768 23.228 0.339768 22.916C0.531768 22.58 0.843768 22.352 1.27577 22.232C1.61177 22.136 1.93577 22.172 2.24777 22.34C2.58377 22.508 2.83577 22.76 3.00377 23.096C3.55577 24.152 4.46777 25.016 5.73977 25.688C7.01177 26.36 8.51177 26.696 10.2398 26.696C12.1358 26.696 13.6958 26.24 14.9198 25.328C16.1678 24.392 16.7918 23.048 16.7918 21.296C16.7918 19.832 16.2518 18.572 15.1718 17.516C14.0918 16.46 12.3998 15.788 10.0958 15.5C7.28777 15.164 5.09177 14.3 3.50777 12.908C1.92377 11.492 1.13177 9.764 1.13177 7.724C1.13177 6.26 1.51577 4.988 2.28377 3.908C3.07577 2.828 4.15577 2 5.52377 1.424C6.89177 0.824002 8.46377 0.524002 10.2398 0.524002C12.2318 0.524002 13.9358 0.98 15.3518 1.892C16.7678 2.78 17.8598 3.836 18.6278 5.06C18.8678 5.444 18.9398 5.816 18.8438 6.176C18.7718 6.536 18.5678 6.812 18.2318 7.004C17.8718 7.172 17.4998 7.196 17.1158 7.076C16.7558 6.956 16.4798 6.728 16.2878 6.392C15.7358 5.552 14.9798 4.82 14.0198 4.196C13.0838 3.548 11.8118 3.212 10.2038 3.188C8.30777 3.188 6.78377 3.596 5.63177 4.412C4.47977 5.204 3.90377 6.368 3.90377 7.904C3.90377 9.128 4.39577 10.196 5.37977 11.108C6.38777 12.02 8.16377 12.656 10.7078 13.016C13.7078 13.448 15.9278 14.372 17.3678 15.788C18.8318 17.18 19.5638 19.004 19.5638 21.26C19.5638 22.988 19.1438 24.464 18.3038 25.688C17.4878 26.888 16.3718 27.8 14.9558 28.424C13.5398 29.048 11.9678 29.36 10.2398 29.36Z"
          fill="black"
        />
        <path
          id="A"
          d="M33.2117 29.18C31.3637 29.18 29.7077 28.748 28.2437 27.884C26.7797 26.996 25.6157 25.796 24.7517 24.284C23.9117 22.772 23.4917 21.068 23.4917 19.172C23.4917 17.252 23.9237 15.536 24.7877 14.024C25.6757 12.512 26.8757 11.324 28.3877 10.46C29.8997 9.572 31.6037 9.128 33.4997 9.128C35.3957 9.128 37.0877 9.572 38.5757 10.46C40.0877 11.324 41.2757 12.512 42.1397 14.024C43.0277 15.536 43.4837 17.252 43.5077 19.172V27.632C43.5077 28.04 43.3757 28.376 43.1117 28.64C42.8477 28.88 42.5117 29 42.1037 29C41.6957 29 41.3597 28.88 41.0957 28.64C40.8317 28.376 40.6997 28.04 40.6997 27.632V25.436C39.8837 26.588 38.8277 27.5 37.5317 28.172C36.2357 28.844 34.7957 29.18 33.2117 29.18ZM33.4997 26.66C34.8917 26.66 36.1277 26.336 37.2077 25.688C38.3117 25.04 39.1757 24.152 39.7997 23.024C40.4477 21.872 40.7717 20.588 40.7717 19.172C40.7717 17.732 40.4477 16.448 39.7997 15.32C39.1757 14.192 38.3117 13.304 37.2077 12.656C36.1277 11.984 34.8917 11.648 33.4997 11.648C32.1317 11.648 30.8957 11.984 29.7917 12.656C28.6877 13.304 27.8117 14.192 27.1637 15.32C26.5157 16.448 26.1917 17.732 26.1917 19.172C26.1917 20.588 26.5157 21.872 27.1637 23.024C27.8117 24.152 28.6877 25.04 29.7917 25.688C30.8957 26.336 32.1317 26.66 33.4997 26.66Z"
          fill="black"
        />
        <path
          id="V"
          d="M54.0011 29C53.4251 29 52.9931 28.688 52.7051 28.064L44.7131 11.108C44.5691 10.796 44.5571 10.484 44.6771 10.172C44.7971 9.86 45.0371 9.62 45.3971 9.452C45.7331 9.284 46.0571 9.26 46.3691 9.38C46.6811 9.5 46.9211 9.716 47.0891 10.028L54.0011 25.004L60.8411 10.028C60.9851 9.692 61.2251 9.476 61.5611 9.38C61.9211 9.26 62.2691 9.272 62.6051 9.416C62.9411 9.584 63.1691 9.836 63.2891 10.172C63.4091 10.484 63.3971 10.784 63.2531 11.072L55.3691 28.064C55.0811 28.688 54.6251 29 54.0011 29Z"
          fill="black"
        />
        <path
          id="I"
          d="M68.6076 29C68.1756 29 67.8276 28.88 67.5636 28.64C67.3236 28.376 67.2036 28.028 67.2036 27.596V10.712C67.2036 10.28 67.3236 9.944 67.5636 9.704C67.8276 9.44 68.1756 9.308 68.6076 9.308C69.0156 9.308 69.3396 9.44 69.5796 9.704C69.8436 9.944 69.9756 10.28 69.9756 10.712V27.596C69.9756 28.028 69.8436 28.376 69.5796 28.64C69.3396 28.88 69.0156 29 68.6076 29ZM68.5716 5.564C68.0436 5.564 67.5876 5.372 67.2036 4.988C66.8196 4.604 66.6276 4.136 66.6276 3.584C66.6276 2.984 66.8196 2.516 67.2036 2.18C67.6116 1.82 68.0796 1.64 68.6076 1.64C69.1116 1.64 69.5556 1.82 69.9396 2.18C70.3476 2.516 70.5516 2.984 70.5516 3.584C70.5516 4.136 70.3596 4.604 69.9756 4.988C69.5916 5.372 69.1236 5.564 68.5716 5.564Z"
          fill="black"
        />
        <path
          id="N"
          d="M77.8978 29C77.4658 29 77.1178 28.88 76.8538 28.64C76.6138 28.376 76.4938 28.04 76.4938 27.632V10.712C76.4938 10.28 76.6138 9.944 76.8538 9.704C77.1178 9.44 77.4658 9.308 77.8978 9.308C78.3058 9.308 78.6298 9.44 78.8698 9.704C79.1338 9.944 79.2658 10.28 79.2658 10.712V11.936C80.0338 11.048 80.9818 10.352 82.1098 9.848C83.2618 9.344 84.4978 9.092 85.8178 9.092C87.5218 9.092 89.0458 9.452 90.3898 10.172C91.7338 10.868 92.7898 11.9 93.5578 13.268C94.3498 14.636 94.7458 16.304 94.7458 18.272V27.632C94.7458 28.016 94.6138 28.34 94.3498 28.604C94.0858 28.868 93.7618 29 93.3778 29C92.9698 29 92.6338 28.868 92.3698 28.604C92.1058 28.34 91.9738 28.016 91.9738 27.632V18.272C91.9738 16.04 91.3498 14.396 90.1018 13.34C88.8778 12.26 87.3538 11.72 85.5298 11.72C84.3538 11.72 83.2858 11.948 82.3258 12.404C81.3898 12.86 80.6458 13.484 80.0938 14.276C79.5418 15.068 79.2658 15.968 79.2658 16.976V27.632C79.2658 28.04 79.1338 28.376 78.8698 28.64C78.6298 28.88 78.3058 29 77.8978 29Z"
          fill="black"
        />
        <path
          id="G"
          d="M108.993 29.18C107.097 29.18 105.417 28.76 103.953 27.92C102.489 27.056 101.337 25.868 100.497 24.356C99.6811 22.844 99.2731 21.116 99.2731 19.172C99.2731 17.204 99.7051 15.464 100.569 13.952C101.433 12.44 102.621 11.264 104.133 10.424C105.645 9.56 107.361 9.128 109.281 9.128C111.225 9.128 112.941 9.56 114.429 10.424C115.941 11.264 117.117 12.44 117.957 13.952C118.821 15.464 119.265 17.204 119.289 19.172V26.516C119.289 28.46 118.857 30.2 117.993 31.736C117.129 33.296 115.953 34.52 114.465 35.408C112.977 36.32 111.273 36.776 109.353 36.776C107.457 36.776 105.789 36.416 104.349 35.696C102.909 35 101.721 34.04 100.785 32.816C100.521 32.528 100.401 32.216 100.425 31.88C100.473 31.544 100.653 31.268 100.965 31.052C101.277 30.836 101.625 30.764 102.009 30.836C102.393 30.908 102.693 31.088 102.909 31.376C103.605 32.24 104.493 32.936 105.573 33.464C106.677 33.992 107.949 34.256 109.389 34.256C110.733 34.256 111.945 33.932 113.025 33.284C114.105 32.636 114.957 31.724 115.581 30.548C116.229 29.372 116.553 27.992 116.553 26.408V24.752C115.833 26.12 114.813 27.2 113.493 27.992C112.197 28.784 110.697 29.18 108.993 29.18ZM109.281 26.66C110.697 26.66 111.945 26.348 113.025 25.724C114.129 25.076 114.993 24.188 115.617 23.06C116.241 21.932 116.553 20.636 116.553 19.172C116.553 17.708 116.241 16.412 115.617 15.284C114.993 14.132 114.129 13.244 113.025 12.62C111.945 11.972 110.697 11.648 109.281 11.648C107.865 11.648 106.605 11.972 105.501 12.62C104.397 13.244 103.533 14.132 102.909 15.284C102.285 16.412 101.973 17.708 101.973 19.172C101.973 20.636 102.285 21.932 102.909 23.06C103.533 24.188 104.397 25.076 105.501 25.724C106.605 26.348 107.865 26.66 109.281 26.66Z"
          fill="black"
        />
        <path
          id="P1"
          d="M124.788 29.576C124.212 29.576 123.72 29.372 123.312 28.964C122.904 28.556 122.7 28.064 122.7 27.488C122.7 26.888 122.904 26.384 123.312 25.976C123.744 25.568 124.236 25.364 124.788 25.364C125.364 25.364 125.856 25.58 126.264 26.012C126.672 26.42 126.876 26.912 126.876 27.488C126.876 28.04 126.672 28.532 126.264 28.964C125.88 29.372 125.388 29.576 124.788 29.576Z"
          fill="black"
        />
        <path
          id="P2"
          d="M132.018 29.576C131.442 29.576 130.95 29.372 130.542 28.964C130.134 28.556 129.93 28.064 129.93 27.488C129.93 26.888 130.134 26.384 130.542 25.976C130.974 25.568 131.466 25.364 132.018 25.364C132.594 25.364 133.086 25.58 133.494 26.012C133.902 26.42 134.106 26.912 134.106 27.488C134.106 28.04 133.902 28.532 133.494 28.964C133.11 29.372 132.618 29.576 132.018 29.576Z"
          fill="black"
        />
        <path
          id="P3"
          d="M139.247 29.576C138.671 29.576 138.179 29.372 137.771 28.964C137.363 28.556 137.159 28.064 137.159 27.488C137.159 26.888 137.363 26.384 137.771 25.976C138.203 25.568 138.695 25.364 139.247 25.364C139.823 25.364 140.315 25.58 140.723 26.012C141.131 26.42 141.335 26.912 141.335 27.488C141.335 28.04 141.131 28.532 140.723 28.964C140.339 29.372 139.847 29.576 139.247 29.576Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default Saving
